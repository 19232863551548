input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.custom-switch-label {
	cursor: pointer;
	text-indent: -9999px;
	width: 35px;
	height: 20px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.custom-switch-label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + .custom-switch-label {
	background: #38a4f8;
}

input:checked + .custom-switch-label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.custom-switch-label:active:after {
	width: 10px;
}
